import { Button } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import menu from "../../assets/menu.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Text, Link, Copyright } from "../Footer/Footer.js";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const Wrapper = styled.div`
	/* position: fixed; */
	top: 0;
	height: 80px;
	width: 100%;
	background: #0e0d22;
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* z-index: 1; */
	@media (max-width: 480px) {
		position: fixed;
		z-index: 999;
	}
`;

const Heading = styled.h3`
	color: #fff;
	font-family: "Poppins", sans-serif;
	font-size: 25px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0 80px 0 100px;
	cursor: pointer;
	@media (max-width: 480px) {
		font-size: 22px;
		margin: 0 0px 0 16px;
	}
`;

const Items = styled.div`
	display: flex;
	@media (max-width: 480px) {
		display: none;
	}
`;

const StyledItems = styled.p`
	margin: 0;
	color: #fff;
	font-family: "DM Sans", sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-right: 30px;
	cursor: pointer;
	@media (max-width: 480px) {
		margin-right: 0;
		margin-left: 30px;
		margin-bottom: 40px;
	}
`;

const Right = styled.div`
	display: flex;
	align-items: center;
`;

const HamburgerMenu = styled.div`
	position: fixed;
	top: 78px;
	left: 0;
	width: 100%;
	height: 92vh; //100vh
	background: #0d0c22;
	box-shadow: -9px 0px 17px 0px rgba(0, 0, 0, 0.1);
	z-index: 9999;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const MenuItems = styled.div`
	margin-top: 28px;
`;

const Line = styled.div`
	margin: 20px;
	width: 90%;
	height: 0.5px;
	background: linear-gradient(
		96deg,
		rgba(149, 1, 244, 0.51) 5.04%,
		rgba(240, 2, 153, 0.51) 90.65%
	);
`;

const ContactDiv = styled.div`
	margin-bottom: 100px;
`;

const Header = ({ setSection }) => {
	const [isMenuClicked, setIsMenuClicked] = useState(false);
	const navigate = useNavigate();

	const handleClick = (section) => {
		navigate(section);
		setIsMenuClicked(false);
	};
	return (
		<>
			<Wrapper>
				<Right>
					<RouterLink to="/">
						<Heading>CTO Ninja</Heading>
					</RouterLink>
					<Items>
						<RouterLink to={"/"}>
							<StyledItems>Home</StyledItems>
						</RouterLink>

						<RouterLink to={"#service"}>
							<StyledItems>Service</StyledItems>
						</RouterLink>

						<RouterLink to={"#about-us"}>
							<StyledItems>About us</StyledItems>
						</RouterLink>

						<RouterLink to={"#case-study"}>
							<StyledItems>Case Study</StyledItems>
						</RouterLink>

						<RouterLink to={"/startup"}>
							<StyledItems>Startup</StyledItems>
						</RouterLink>
					</Items>
				</Right>
				<Button
					sx={{ textTransform: "initial" }}
					variant="contained"
					className="getAQuote"
					href="https://calendly.com/shivamshivk-1/30_min?month=2023-10"
					target="_blank">
					Get a Quote
				</Button>
				<Button
					variant="contained"
					className="menuIcon"
					onClick={() => setIsMenuClicked(!isMenuClicked)}
					style={{ transition: "all 0.3s ease-in-out" }}>
					{!isMenuClicked ? (
						<img
							src={menu}
							alt=""
							style={{ transition: "all 0.3s ease-in-out" }}
						/>
					) : (
						<CloseRoundedIcon
							sx={{
								width: "30px",
								height: "30px",
								transition: "all 0.3s ease-in-out",
							}}
						/>
					)}
				</Button>
			</Wrapper>
			{isMenuClicked && (
				<HamburgerMenu>
					<MenuItems>
						<StyledItems onClick={() => handleClick("/#top")}>Home</StyledItems>
						<StyledItems onClick={() => handleClick("/#service")}>
							Service
						</StyledItems>
						<StyledItems onClick={() => handleClick("/#about-us")}>
							About us
						</StyledItems>
						<StyledItems onClick={() => handleClick("/#case-study")}>
							Case Study
						</StyledItems>
						<StyledItems onClick={() => handleClick("/startup")}>
							Startup
						</StyledItems>
					</MenuItems>
					<ContactDiv>
						<Line />
						<div style={{ margin: "30px 30px" }}>
							<Text style={{ marginBottom: "28px", color: "#fff" }}>
								Contact Us
							</Text>
							<Link style={{ display: "flex", marginBottom: "20px" }}>
								<CallOutlinedIcon sx={{ height: "20px", marginRight: "4px" }} />
								+91 7903465947
							</Link>
							<Link style={{ display: "flex", marginBottom: "20px" }}>
								<EmailOutlinedIcon
									sx={{ height: "20px", marginRight: "4px" }}
								/>
								shivam@ctoninja.tech
							</Link>
							<Link style={{ display: "flex", marginBottom: "20px" }}>
								<LocationOnOutlinedIcon
									sx={{ height: "20px", marginRight: "4px" }}
								/>
								AK 65, Action Area 1 Newtown, Kolkata, West Bengal
							</Link>
						</div>
						<div style={{ margin: "30px" }}>
							<Text style={{ marginBottom: "24px", color: "#fff " }}>
								Follow Us
							</Text>
							<div style={{ display: "flex" }}>
								<Link>
									<FacebookIcon
										sx={{ marginRight: "12px", fontSize: "30px" }}
									/>
								</Link>
								<Link>
									<InstagramIcon
										sx={{ marginRight: "12px", fontSize: "30px" }}
									/>
								</Link>
								<Link>
									<YouTubeIcon sx={{ marginRight: "12px", fontSize: "30px" }} />
								</Link>
							</div>
						</div>
						<Copyright style={{ color: "#fff" }}>
							Copyright©2022{" "}
							<span style={{ textDecoration: "underline" }}>CTO Ninja</span>
						</Copyright>
					</ContactDiv>
				</HamburgerMenu>
			)}
		</>
	);
};

export default Header;
