import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

const colorChange = keyframes`
  0% { background-position: 0% 50%; }
  33% { background-position: 100% 50%; }
  66%, 100% { color: #fff; }
`;

const Wrapper = styled.div`
  /* height: 100vh; */
  background: #0d0c22;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h1`
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 54px; /*60px*/
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -2.8px;
  padding-top: 80px; //66
  @media (max-width: 480px) {
    font-size: 38px;
    color: #fff ;
    text-align: center;
    font-weight: 700;
  }
`;

const TagLine = styled.p`
  color: #d9d9d9;
  font-family: DM Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 80px; //40
  @media (max-width: 480px) {
    width: 90%;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    margin-top: 60px;
  }
`;

const Description = styled.p`
  color: #d9d9d9;
  text-align: center;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-top: 28px;
  @media (max-width: 480px) {
    width: 92%;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
    margin-top: 60px;
  }
`;

const Explore = styled.p`
  color: #d9d9d9;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 40px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 480px) {
    margin-top: 84px;
    padding: 12px 15px;
    font-size: 14px;
  }
`;

const HeroSection = () => {
  return (
    <Wrapper>
      <Heading class='py-14 text-center sm:text-10xl leading-none select-none tracking-tightest font-extrabold'>
        <span
          data-content='App Development.'
          class='relative  before:content-[attr(data-content)] before:w-full before:z-0 before:block before:absolute before:top-0 before:px-2 before:bottom-0 before:left-0 before:text-center before:text-white before:animate-gradient-background-1'
        >
          <span class='px-2 text-transparent bg-clip-text bg-gradient-to-r from-gradient-1-start to-gradient-1-end animate-gradient-foreground-1'>
            App Development.
          </span>
        </span>
        <span
          data-content='UI/UX.'
          class='relative  before:content-[attr(data-content)] before:w-full before:z-0 before:block before:absolute before:top-0 before:px-2 before:bottom-0 before:left-0 before:text-center before:text-white before:animate-gradient-background-2'
        >
          <span class='px-2 text-transparent bg-clip-text bg-gradient-to-r from-gradient-2-start to-gradient-2-end animate-gradient-foreground-2'>
            UI/UX.
          </span>
        </span>
        <span
          data-content='Web Development.'
          class='relative  before:content-[attr(data-content)] before:w-full before:z-0 before:block before:absolute before:top-0 before:px-2 before:bottom-0 before:left-0 before:text-center before:text-white before:animate-gradient-background-3'
        >
          <span class='px-2 text-transparent bg-clip-text bg-gradient-to-r from-gradient-3-start to-gradient-3-end animate-gradient-foreground-3'>
            Web Development.
          </span>
        </span>
      </Heading>
      <TagLine>CTO Ninja- where innovation meets code</TagLine>
      <Description>
        We’re digital architects crafting the future through our development{' '}
        <br />
        agency. A team of experts , who builds scalable technology, your digital
        CTO.
      </Description>
      <Button
        sx={{ textTransform: 'initial' }}
        variant='contained'
        className='getStarted'
        href='https://calendly.com/shivamshivk-1/30_min?month=2023-10'
        target='_blank'
      >
        Get Started{' '}
      </Button>
      <Explore>EXPLORE OUR SERVICES</Explore>
    </Wrapper>
  );
};

export default HeroSection;
