import React from 'react';
import conversion from '../../assets/conversion.svg';
import data from '../../assets/data.svg';
import {
  Description,
  DescriptionText,
  Details,
  DetailsWrapper,
  Line,
  StyledHeading,
  StyledImage,
  StyledLine,
  StyledNumber,
  StyledSvg,
  TagLine,
  Wrapper,
} from './Service1';
import { Button } from '@mui/material';
import app from '../../assets/app.svg';
import styled from 'styled-components';
import { Description2, Wrapper2 } from './Service2';

const Heading = styled(StyledHeading)`
  background: var(--y, linear-gradient(96deg, #0f82fc 4.96%, #05bceb 83.63%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Image = styled(StyledImage)`
  margin-left: 60px;
  margin-right: 0;
  @media (max-width: 480px) {
    display: none;
  }
`;

const MobileImage = styled(StyledImage)`
  display: none;
  @media (max-width: 480px) {
    display: block;
  }
`;

const Service3 = () => {
  return (
    <Wrapper2>
      <StyledLine
        style={{
          background:
            'linear-gradient(96deg, rgba(15, 130, 252, 0.08) 4.96%, #05BCEB 83.63%)',
        }}
      />
      <StyledNumber
        style={{
          background: 'linear-gradient(96deg, #0F82FC 4.96%, #05BCEB 83.63%)',
        }}
      >
        3
      </StyledNumber>
      <Heading>App Development</Heading>
      <TagLine>Bringing Your App Vision to Life</TagLine>
      <Description2>
        In the mobile-driven world, having a standout app is essential for
        success. Our app development team specializes in turning your concept
        into reality, whether you’re targeting iOS, Android, or both. We build
        native and cross-platform apps that combine stunning design with
        seamless functionality, delivering an exceptional user experience.
      </Description2>
      <Details>
        <MobileImage src={app} alt='' />
        <Wrapper>
          <DetailsWrapper>
            <StyledSvg src={conversion} alt='' />
            <Line>Services include:</Line>
            <DescriptionText>
              Native App Development (iOS and Android) , Cross-Platform App
              Development , App Prototyping , App Testing and Quality Assurance
              , App Maintenance and Support
            </DescriptionText>
          </DetailsWrapper>
          <DetailsWrapper style={{ marginTop: '40px' }}>
            <StyledSvg src={data} alt='' />
            <Line>Benefits:</Line>
            <DescriptionText>
              Cross-Platform Reach: Reach both iOS and Android users with
              cross-platform apps.
            </DescriptionText>
            <DescriptionText>
              Scalability: Apps that grow with your user base.
            </DescriptionText>
            <DescriptionText>
              User Retention: Engaging apps and regular maintenance keep users
              coming back.
            </DescriptionText>
            <DescriptionText>
              Quality Assurance: Bug-free apps provide a seamless user
              experience.
            </DescriptionText>
          </DetailsWrapper>
          <Button
            sx={{
              textTransform: 'initial',
            }}
            variant='contained'
            className='getStarted button'
            href='https://calendly.com/shivamshivk-1/30_min?month=2023-10'
            target='_blank'
          >
            Get Started{' '}
          </Button>
        </Wrapper>
        <Image src={app} alt='' />
      </Details>
    </Wrapper2>
  );
};

export default Service3;
