import React from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Wrapper = styled.div`
	height: 140px;
	background: #373647;
	box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-around;
	align-items: center;
	@media (max-width: 480px) {
		height: 86px;
	}
`;

const Number = styled.p`
	color: #fff;
	font-family: DM Sans;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	@media (max-width: 480px) {
		font-size: 20px;
	}
`;

const Text = styled.p`
	color: #d9d9d9;
	font-family: DM Sans;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	@media (max-width: 480px) {
		font-size: 12px;
	}
`;

const Wrapper2 = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	@media (max-width: 480px) {
		gap: 10px;
	}
`;

const Line = styled.div`
	width: 0.5px;
	height: 98px;
	background: #4f4e6a;
`;

const Clients = () => {
	return (
		<Wrapper>
			<Wrapper2>
				<VisibilitySensor>
					{({ isVisible }) => (
						<Number>
							{isVisible ? (
								<>
									<CountUp end={75} duration={5} />+
								</>
							) : (
								"+"
							)}
						</Number>
					)}
				</VisibilitySensor>
				<Text>Project Delivered</Text>
			</Wrapper2>
			<Line />
			<Wrapper2>
				<VisibilitySensor>
					{({ isVisible }) => (
						<Number>
							{isVisible ? (
								<>
									<CountUp end={60} duration={5} />+
								</>
							) : (
								"+"
							)}
						</Number>
					)}
				</VisibilitySensor>
				<Text>Happy Clients</Text>
			</Wrapper2>
			<Line />
			<Wrapper2>
				<VisibilitySensor>
					{({ isVisible }) => (
						<Number>
							{isVisible ? (
								<>
									<CountUp end={1000} duration={5} />+
								</>
							) : (
								"+"
							)}
						</Number>
					)}
				</VisibilitySensor>
				<Text>Total Coffees</Text>
			</Wrapper2>
		</Wrapper>
	);
};

export default Clients;
