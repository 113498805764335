import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Description, TagLine } from '../Services/Service1';
import { Button } from '@mui/material';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { data } from './data';

const Wrapper = styled.div`
  background: #0d0c22;
  padding-top: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;
  @media (max-width: 480px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Text = styled.p`
  color: #8a00ff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 48px;
  @media (max-width: 480px) {
    margin-bottom: 0px;
  }
`;

const PortfolioWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 480px) {
    overflow-x: scroll;
    justify-content: flex-start;
    scroll-behavior: smooth;
    gap: 20px;
    padding: 0px 16px;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const Card = styled.div`
  width: 500px;
  height: 466px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  @media (max-width: 480px) {
    width: 251px;
    /* height: 256px; */
    height: 100%;
    padding: 14px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  text-align: center;
  @media (max-width: 480px) {
    height: 182px;
  }
`;

const ProjectName = styled.p`
  color: #000;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 24px;
  margin-bottom: 10px;
  @media (max-width: 480px) {
    font-size: 15px;
    margin-top: 15px;
  }
`;

const Area = styled.p`
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.7;
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const DescriptionText = styled(Description)`
  width: 600px;
  @media (max-width: 480px) {
    width: 84%;
    margin-bottom: 44px;
  }
`;

const ProgressiveImage = ({ src, placeholder, alt }) => {
  const [currentSrc, setCurrentSrc] = useState(placeholder);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setCurrentSrc(src);
  }, [src]);

  return <StyledImage src={currentSrc} alt={alt} />;
};

const PortFolio = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        setIsMobile(window.innerWidth <= 480);
      },
      false
    );
  }, []);

  const handleNext = () => {
    setCurrentIndex((currentIndex + 2) % data.length);
  };

  const handlePrev = () => {
    setCurrentIndex((currentIndex - 2 + data.length) % data.length);
  };

  return (
    <Wrapper>
      <Text>Portfolio</Text>
      <TagLine>
        Enjoy the legacy of our work as we take you through our past projects.
      </TagLine>
      <DescriptionText>Examples of previous projects</DescriptionText>
      <PortfolioWrapper>
        <Button variant='contained' className='iconButton' onClick={handlePrev}>
          <NavigateBeforeRoundedIcon sx={{ fontSize: '60px' }} />
        </Button>
        {(isMobile ? data : data.slice(currentIndex, currentIndex + 2)).map(
          (data, index) => {
            return (
              <a
                style={{ textDecoration: 'none' }}
                href={data.link}
                target='_blank'
                rel='noreferrer'
                key={`${index}-${data.text}`}
              >
                <Card key={`${index}-${data.text}`}>
                  <ProgressiveImage
                    src={data.img}
                    placeholder='https://via.placeholder.com/647x480.png/cccccc/000000?Text=647x480'
                    alt={data.link}
                  />
                  <ProjectName>{data.text}</ProjectName>
                  <Area>{data.area}</Area>
                </Card>
              </a>
            );
          }
        )}
        <Button variant='contained' className='iconButton' onClick={handleNext}>
          <NavigateNextRoundedIcon sx={{ fontSize: '60px' }} />
        </Button>
      </PortfolioWrapper>
    </Wrapper>
  );
};

export default PortFolio;
