import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Root from "./Pages/Root.js";
import Startup from "./Pages/Startup.js";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";

function App() {
	return (
		<div className="app">
			<Router>
				<Header />
				<Routes>
					<Route path="/" element={<Root />} />
					<Route path="/startup" element={<Startup />} />
				</Routes>
				<Footer />
			</Router>
		</div>
	);
}

export default App;
