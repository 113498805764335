import React from 'react';
import styled from 'styled-components';
import conversion from '../../assets/conversion.svg';
import data from '../../assets/data.svg';
import web from '../../assets/web.svg';
import {
  Description,
  DescriptionText,
  Details,
  DetailsWrapper,
  Line,
  StyledHeading,
  StyledImage,
  StyledLine,
  StyledNumber,
  StyledSvg,
  TagLine,
  Wrapper,
} from './Service1';
import { Button } from '@mui/material';

const Heading = styled(StyledHeading)`
  background: var(--g, linear-gradient(96deg, #fe6e38 4.97%, #fda329 83.63%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Wrapper2 = styled(Wrapper)`
  @media (max-width: 480px) {
    padding-top: 50px;
  }
`;

export const Description2 = styled(Description)`
  width: 968px;
  @media (max-width: 480px) {
    width: 86%;
  }
`;

const Service2 = () => {
  return (
    <Wrapper2>
      <StyledLine
        style={{
          background:
            'linear-gradient(96deg, rgba(254, 110, 56, 0.08) 4.96%, #FDA329 83.63%)',
        }}
      />
      <StyledNumber
        style={{
          background:
            'var(--g, linear-gradient(96deg, #FE6E38 4.97%, #FDA329 83.63%))',
        }}
      >
        2
      </StyledNumber>
      <Heading>Web Development</Heading>
      <TagLine>Web Magic That Engages</TagLine>
      <Description2>
        Your website is your digital storefront, and we’re here to make it truly
        exceptional. Our web development experts craft responsive, interactive,
        and visually stunning websites that capture your audience’s attention
        and leave a lasting impression.
      </Description2>
      <Details>
        <StyledImage src={web} alt='' />
        <Wrapper>
          <DetailsWrapper>
            <StyledSvg src={conversion} alt='' />
            <Line>Services include:</Line>
            <DescriptionText>
              Front-End and Back-End Development , E-Commerce Solutions ,
              Content Management Systems (CMS) , Website Redesign and
              Optimization , Website Maintenance
            </DescriptionText>
          </DetailsWrapper>
          <DetailsWrapper style={{ marginTop: '40px' }}>
            <StyledSvg src={data} alt='' />
            <Line>Benefits:</Line>
            <DescriptionText>
              Increased Conversion Rates: User-friendly websites drive higher
              conversions.
            </DescriptionText>
            <DescriptionText>
              Optimized Performance: Faster loading times reduce bounce rates.
            </DescriptionText>
            <DescriptionText>
              E-Commerce Success: E-commerce solutions boost sales with seamless
              experiences.
            </DescriptionText>
            <DescriptionText>
              Search Engine Visibility: Well-structured websites enhance online
              visibility.
            </DescriptionText>
          </DetailsWrapper>
          <Button
            sx={{
              textTransform: 'initial',
            }}
            variant='contained'
            className='getStarted button'
            href='https://calendly.com/shivamshivk-1/30_min?month=2023-10'
            target='_blank'
          >
            Get Started{' '}
          </Button>
        </Wrapper>
      </Details>
    </Wrapper2>
  );
};

export default Service2;
