import React from 'react';
import styled from 'styled-components';
import uiux from '../../assets/uiux.svg';
import conversion from '../../assets/conversion.svg';
import data from '../../assets/data.svg';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  background: #0d0c22;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledNumber = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: var(--k, linear-gradient(96deg, #9501f4 5.04%, #f00299 90.65%));
  border-radius: 50%;
  color: #0e0d22;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
`;

export const StyledLine = styled.div`
  width: 1px;
  height: 100px;
  background: linear-gradient(
    96deg,
    rgba(149, 1, 244, 0.08) 5.04%,
    #f00299 90.65%
  );
  margin-top: 28px; //initially no margin
`;

export const StyledHeading = styled.h1`
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--k, linear-gradient(96deg, #9501f4 5.04%, #f00299 90.65%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 20px;
  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

export const TagLine = styled.p`
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  @media (max-width: 480px) {
    width: 88%;
    font-size: 18px;
    margin-top: 30px;
  }
`;

export const Description = styled.p`
  color: #d9d9d9;
  text-align: center;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin-top: 16px;
  width: 898px;
  margin-bottom: 40px;
  @media (max-width: 480px) {
    width: 86%;
    margin-top: 20px;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 66px;
  }
`;

export const StyledImage = styled.img`
  width: 628px;
  height: 540px;
  flex-shrink: 0;
  border-radius: 5px;
  /* margin-left: 130px; */
  margin-right: 60px;
  @media (max-width: 480px) {
    margin-right: 0;
    width: 100%;
    height: auto;
  }
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
  @media (max-width: 480px) {
    padding: 0px 16px;
    flex-direction: column;
  }
`;

export const StyledSvg = styled.img`
  width: 40px;
  height: 40px;
  @media (max-width: 480px) {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }
`;

export const DescriptionText = styled.p`
  color: #d9d9d9;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  line-height: 31px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const DetailsWrapper = styled.div`
  @media (max-width: 480px) {
    margin-top: 50px;
  }
`;

export const Line = styled(TagLine)`
  font-size: 20px;
  margin-top: 12px;
  text-align: initial;
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
  }
`;

const Service1 = () => {
  return (
    <Wrapper>
      <StyledLine />
      <StyledNumber>1</StyledNumber>
      <StyledHeading>UI/UX Design</StyledHeading>
      <TagLine>Crafting User-Centric Excellence</TagLine>
      <Description>
        {' '}
        User experience is at the heart of every successful digital product. Our
        UI/UX designers are your partners in creating seamless and visually
        stunning interfaces. We design with your users in mind, ensuring that
        every click, swipe, and interaction is a delightful journey.
      </Description>
      <Details>
        <StyledImage src={uiux} alt='' />
        <Wrapper>
          <DetailsWrapper>
            <StyledSvg src={conversion} alt='' />
            <Line>Services include:</Line>
            <DescriptionText>
              User Research and Analysis , Wireframing and Prototyping , Visual
              Design , Interaction Design , Usability Testing
            </DescriptionText>
          </DetailsWrapper>
          <DetailsWrapper style={{ marginTop: '40px' }}>
            <StyledSvg src={data} alt='' />
            <Line>Benefits:</Line>
            <DescriptionText>
              Enhanced User Engagement: Designs that captivate users and boost
              engagement.{' '}
            </DescriptionText>{' '}
            <DescriptionText>
              Higher Conversions: User-friendly interfaces lead to increased
              conversion rates.
            </DescriptionText>
            <DescriptionText>
              Brand Credibility: Well-designed digital products build trust and
              enhance your brand.
            </DescriptionText>
            <DescriptionText>
              Reduced Development Costs: Early user needs identification saves
              on redesign expenses.
            </DescriptionText>
          </DetailsWrapper>
          <Button
            sx={{
              textTransform: 'initial',
            }}
            variant='contained'
            className='getStarted button'
            href='https://calendly.com/shivamshivk-1/30_min?month=2023-10'
            target='_blank'
          >
            Get Started{' '}
          </Button>
        </Wrapper>
      </Details>
    </Wrapper>
  );
};

export default Service1;
