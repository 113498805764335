import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header";
import HeroSection from "../components/HeroSection/HeroSection";
import Service1 from "../components/Services/Service1";
import Service2 from "../components/Services/Service2";
import Service3 from "../components/Services/Service3";
import Mission from "../components/Mission/Mission";
import Clients from "../components/Clients/Clients";
import PortFolio from "../components/Portfolio/PortFolio";
import Testimonials from "../components/Testimonials/Testimonials";
import Footer from "../components/Footer/Footer";
import up from "../assets/up.svg";
import { Button } from "@mui/material";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const DIV = styled.div`
	position: fixed;
	right: 20px;
	z-index: 1;
	bottom: 20px;
	background-color: #0e0d22;
	height: 70px;
	width: 70px;
	@media (max-width: 480px) {
		right: 0;
		bottom: 0;
	}
`;

const Root = () => {
	const [section, setSection] = useState(null);
	const topRef = useRef(null);
	const service1Ref = useRef(null);
	const missionRef = useRef(null);
	const caseStudyRef = useRef(null);
	const testimonialRef = useRef(null);
	const service2Ref = useRef(null);
	const service3Ref = useRef(null);
	const location = useLocation();

	useEffect(() => {
		switch (location.hash) {
			case "#top":
				window.scrollTo(0, 0);
				break;
			case "#service":
				service1Ref.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
				break;
			case "#about-us":
				missionRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
				break;
			case "#case-study":
				caseStudyRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
				break;
			case "#testimonial":
				testimonialRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
				break;
			case "#service2":
				service2Ref.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
				break;
			case "#service3":
				service3Ref.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
				break;
			default:
				break;
		}
	}, [location.hash]);

	return (
		<div ref={topRef}>
			{/* <Header setSection={setSection} /> */}
			{/* <DIV>
        <Button
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
        >
          <img src={up} alt='' style={{ width: '100%', height: '100%' }} />
        </Button>
      </DIV> */}
			<HeroSection />
			<div ref={service1Ref}>
				<Service1 />
			</div>
			<div ref={service2Ref}>
				<Service2 />
			</div>
			<div ref={service3Ref}>
				<Service3 />
			</div>
			<div ref={missionRef}>
				<Mission />
			</div>
			<div>
				<Clients />
			</div>
			<div ref={caseStudyRef}>
				<PortFolio />
			</div>
			<div ref={testimonialRef}>
				<Testimonials />
			</div>
		</div>
	);
};

export default Root;
