import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Description, TagLine } from '../Services/Service1';
import clapingoLogo from '../../assets/clapingoLogo.svg';
import gogoBus from '../../assets/gogoBus.png';
import { Button } from '@mui/material';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import up from '../../assets/up.svg';

const data = [
  {
    tagLine: 'It was a great experience',
    description:
      'As a startup founder, I had to outsource various aspects of my business when I started. Be it social media marketing, hiring, Technology, content creation. But I was disappointed on every aspects except Technology because CTO Ninja took care of the Technology. ',
    logo: clapingoLogo,
    company: 'Clapingo',
    userName: 'Sameer Aggarwal',
    post: 'CEO, Clapingo',
  },
  {
    tagLine: 'It was a good experience',
    description:
      'Its an excellent experience to work with enthusiastic, committed and creative professional at CTO Ninja who respect the timeline without compromising quality. Impressed with the creative solutions and fresh designs.',
    logo: gogoBus,
    company: 'gogoBus',
    userName: 'Abhishek Narayan',
    post: 'CTO, Busyar',
  },
  {
    tagLine: 'It was a great experience',
    description:
      'As a startup founder, I had to outsource various aspects of my business when I started. Be it social media marketing, hiring, Technology, content creation. But I was disappointed on every aspects except Technology because CTO Ninja took care of the Technology. ',
    logo: clapingoLogo,
    company: 'Clapingo',
    userName: 'Sameer Aggarwal',
    post: 'CEO, Clapingo',
  },
];

const Wrapper = styled.div`
  background: #0d0c22;
  padding-top: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;
  @media (max-width: 480px) {
    padding-top: 0;
  }
`;

const Text = styled.p`
  color: #ff0088;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 48px;
  @media (max-width: 480px) {
    margin-bottom: 0;
  }
`;

const Card = styled.div`
  position: relative;
  width: ${(props) => (props.middleCard ? '344px' : '286px')};
  height: ${(props) => (props.middleCard ? '550px' : '463px')};
  flex-shrink: 0;
  border-radius: ${(props) => (props.middleCard ? '19.716px' : '11.972px')};
  border: ${(props) =>
    props.middleCard ? '1.19px solid #9501F4' : '0.723px solid #9501f4'};
  background: ${(props) => (props.middleCard ? '#fff' : '#130d2f')};
  padding: 24px;
  color: ${(props) => (props.middleCard ? '#000' : '#d9d9d9')};
  @media (max-width: 480px) {
    width: 251px;
    height: 320px;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    color: #000;
  }
`;

const BottomDiv = styled.div`
  position: absolute;
  bottom: 24px;
  width: 100%;
  padding-right: 48px;
  @media (max-width: 480px) {
    bottom: 10px;
  }
`;

const Tag = styled.p`
  /* color: #d9d9d9; */
  font-family: DM Sans;
  font-size: 16.18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 14px;
  }
`;

const TagDescription = styled.p`
  /* color: #d9d9d9; */
  font-family: DM Sans;
  font-size: 16.42px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.3px; /* 135.81% */
  margin-bottom: 40px;
  @media (max-width: 480px) {
    font-size: 12px;
    margin-bottom: 14px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLogo = styled.img`
  /* background: lightgray; */
  /* width: 31.926px; */
  height: 39.022px;
  flex-shrink: 0;
  margin-right: 8px;
  @media (max-width: 480px) {
    height: 24px;
    /* width: 24px; */
  }
`;

const Name = styled.p`
  /* color: #d9d9d9; */
  font-family: DM Sans;
  font-size: 15.3px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Post = styled.p`
  /* color: #d9d9d9; */
  font-family: DM Sans;
  font-size: 14.64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const TestimonialsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  @media (max-width: 480px) {
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    gap: 20px;
    padding: 0px 16px;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const Hr = styled.hr`
  background: D9D9D9;
  margin-top: 24px;
  margin-bottom: 12px;
  @media (max-width: 480px) {
    margin: 6px 0;
  }
`;

const StyledUp = styled.div`
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  align-self: self-end;
  margin-right: 40px;
  border-radius: 50%;
  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
    padding-right: 20px;
    margin-top: 40px;
  }
`;

const Desc = styled(Description)`
  margin-bottom: 44px;
`;

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        setIsMobile(window.innerWidth <= 480);
      },
      false
    );
  }, []);

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % data.length);
  };

  const handlePrev = () => {
    setCurrentIndex((currentIndex - 1 + data.length) % data.length);
  };
  return (
    <>
      <Wrapper>
        <Text>Testimonials</Text>
        <TagLine>Words from Our Clients</TagLine>
        <Desc>
          Hear the tales of satisfaction from those who have embarked on this
          transformative journey with us.
        </Desc>
        <TestimonialsWrapper>
          <Button
            variant='contained'
            className='iconButton'
            onClick={handlePrev}
          >
            <NavigateBeforeRoundedIcon sx={{ fontSize: '60px' }} />
          </Button>
          {[...data, ...data, ...data]
            .slice(currentIndex, currentIndex + 3)
            .map((item, index) => {
              return (
                <Card key={index} middleCard={index === 1}>
                  <Tag>{item.tagLine}</Tag>
                  <TagDescription>{item.description}</TagDescription>
                  <BottomDiv>
                    <LogoWrapper>
                      <StyledLogo src={item.logo} alt='' />
                      <Tag style={{ marginBottom: '0' }}>{item.company}</Tag>
                    </LogoWrapper>
                    <Hr />
                    <Name>{item.userName}</Name>
                    <Post>{item.post}</Post>
                  </BottomDiv>
                </Card>
              );
            })}
          <Button
            variant='contained'
            className='iconButton'
            onClick={handleNext}
          >
            <NavigateNextRoundedIcon sx={{ fontSize: '60px' }} />
          </Button>
        </TestimonialsWrapper>
        <StyledUp>
          <Button
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
          >
            <img src={up} alt='' style={{ width: '100%', height: '100%' }} />
          </Button>
        </StyledUp>
      </Wrapper>
    </>
  );
};

export default Testimonials;
