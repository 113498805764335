import React from 'react';
import styled from 'styled-components';
import { Description, TagLine } from '../Services/Service1';
import agile from '../../assets/agile.svg';
import modern from '../../assets/modern.svg';
import modernTech from '../../assets/modernTech.svg';

const data = [
  {
    icon: agile,
    tag: 'Proven Expertise',
    text: 'Our team comprises industry experts who stay at the forefront of technology trends to deliver cutting-edge solutions.',
  },
  {
    icon: modern,
    tag: 'User-Centric Approach',
    text: 'We prioritize user experience, ensuring your digital products not only look great but are intuitive and enjoyable to use.',
  },
  {
    icon: modernTech,
    tag: 'Collaborative Process',
    text: ' We believe in close collaboration with our clients, making you an integral part of the creative process.',
  },
  {
    icon: agile,
    tag: 'Transparent Communication',
    text: ' We maintain transparent communication, keeping you informed at every stage of your project.',
  },
  {
    icon: modern,
    tag: 'Timely Deliveries',
    text: ' We are resolute about delivering your projects punctually and within your budget.',
  },
  {
    icon: modernTech,
    tag: 'Agile Methodology',
    text: 'We strongly believe that a iterative approach to building with constant feedback is the way to go.',
  },
];

const Wrapper = styled.div`
  background: #0d0c22;
  padding-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;

  @media (max-width: 480px) {
    padding-bottom: 40px;
  }
`;

const Text = styled.p`
  color: #0f82fc;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 48px;
  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

const Wrapper2 = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 0 136px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    flex-direction: column;
    padding: 0 16px;
    margin-top: -14px;
  }
`;

const Card = styled.div`
  width: calc(33.33% - (2 * 40px / 3));
  height: auto;
  margin-bottom: 40px;
  @media (max-width: 480px) {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Tag = styled.p`
  color: #fff;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 10px;
  @media (max-width: 480px) {
    font-size: 16px;
    margin-top: 10px;
  }
`;

const DescText = styled.p`
  color: #d9d9d9;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  @media (max-width: 480px) {
    font-size: 14px;
    text-align: center;
    width: 90%;
  }
`;

const DescriptionText = styled(Description)`
  width: 962px;
  @media (max-width: 480px) {
    width: 90%;
    margin-bottom: 44px;
  }
`;

const StyledImage = styled.img`
  @media (max-width: 480px) {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }
`;

const WhyUsText = styled(Text)`
  color: #ff6347;
  @media (max-width: 480px) {
    margin-bottom: 44px;
  }
`;
const Mission = () => {
  return (
    <Wrapper>
      <Text>Our Mission</Text>
      <TagLine>
        We are a team of expert developers. We build tech that scales
      </TagLine>
      <DescriptionText>
        At CTO Ninja, our mission is to illuminate the digital landscape with
        innovation and empower businesses through transformative technology
        solutions. We are driven by a passion for excellence and a commitment to
        pushing the boundaries of what’s possible in the digital realm.
      </DescriptionText>
      <WhyUsText>Why Us ?</WhyUsText>
      <Wrapper2>
        {data.map((item, index) => {
          return (
            <Card key={`${index}-${item.icon}`}>
              <StyledImage src={item.icon} alt={item.tag} />
              <Tag>{item.tag}</Tag>
              <DescText>{item.text}</DescText>
            </Card>
          );
        })}
      </Wrapper2>
    </Wrapper>
  );
};

export default Mission;
