import React, { useEffect, useRef } from "react";
import hero from "../assets/hero.png";
import heroMobile from "../assets/hero-mobile.png";
import styled from "styled-components";
import ScaleIcon from "../assets/scale.svg";
import ProductIcon from "../assets/product.svg";
import HiringIcon from "../assets/Group.svg";
import TeamIcon from "../assets/team.svg";
import AppDevelopmentIcon from "../assets/app-development.svg";
import WebDevelopmentIcon from "../assets/web-development.svg";
import DesignIcon from "../assets/ui-ux.svg";
import agile from "../assets/agile.svg";
import modern from "../assets/modern.svg";
import modernTech from "../assets/modernTech.svg";
import Clients from "../components/Clients/Clients";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";

const HeroSection = ({ ref }) => {
	return (
		<div ref={ref} className="relative">
			<img
				src={hero}
				alt=""
				className="hidden md:block"
				style={{ width: "100%", height: "100%" }}
			/>
			<img
				src={heroMobile}
				alt=""
				className="block md:hidden"
				style={{ width: "100%", height: "100%" }}
			/>
			<div className="absolute w-2/3 top-5 sm:top-20 lg:top-36 left-7 sm:left-10 md:left-0 md:right-0 m-auto">
				<div className="flex flex-col align-center justify-center">
					<div className="text-2xl md:text-5xl font-semibold text-zinc-200">
						Elevate Your Startup <br />
						Journey
					</div>
					<br />
					<br className="hidden md:block" />
					<div className="text-zinc-200 text-md md:text-2xl ">
						We understand that startups are the lifeblood of innovation and
						entrepreneurship. We're excited to introduce our dedicated Startup
						Section, where we provide comprehensive support to emerging
						businesses, helping them turn their groundbreaking ideas into
						reality.
					</div>
					<br />
					<br className="hidden lg:block" />
					<div className="block ">
						<Button
							sx={{ textTransform: "initial" }}
							variant="contained"
							className="getStarted"
							style={{
								marginTop: 10,
								// left: -15,
							}}>
							Get Started
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

const ConsultingServices = ({ ref }) => {
	const data = [
		{
			icon: ScaleIcon,
			title: "Building Tech in Early Stage Startup",
			description:
				"For transactional platforms, a user-friendly UI/UX can streamline the checkout process.",
		},
		{
			icon: ProductIcon,
			title: "Product Management",
			description:
				"For transactional platforms, a user-friendly UI/UX can streamline the checkout process.",
		},
		{
			icon: HiringIcon,
			title: "Tech Team Hiring",
			description:
				"For transactional platforms, a user-friendly UI/UX can streamline the checkout process.",
		},
		{
			icon: TeamIcon,
			title: "Team Management",
			description:
				"For transactional platforms, a user-friendly UI/UX can streamline the checkout process.",
		},
		{
			icon: AppDevelopmentIcon,
			title: "App Development",
			description:
				"For transactional platforms, a user-friendly UI/UX can streamline the checkout process.",
		},
		{
			icon: WebDevelopmentIcon,
			title: "Web Development",
			description:
				"For transactional platforms, a user-friendly UI/UX can streamline the checkout process.",
		},
		{
			icon: DesignIcon,
			title: "UI/UX",
			description:
				"For transactional platforms, a user-friendly UI/UX can streamline the checkout process.",
		},
	];

	const ItemComponent = ({ icon, title, description }) => {
		return (
			<div className="flex flex-row items-center justify-center w-96">
				<img src={icon} className="w-12" alt="" />
				<div className="pl-7">
					<div className="text-xl md:text-2xl font-bold text-zinc-200">
						{title}
					</div>
					<div className="text-zinc-200 text-sm md:text-md pt-3">
						{description}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div ref={ref} className="py-20">
			<div className="px-2 text-center pb-10 text-xl text-transparent bg-clip-text bg-gradient-to-r from-gradient-2-start to-gradient-2-end">
				Our consulting services include
			</div>
			<div className="md:flex flex-wrap justify-evenly">
				{data.map((item) => (
					<div
						key={item.title}
						className="md:w-1/3 justify-center flex items-start p-4">
						<ItemComponent
							icon={item.icon}
							title={item.title}
							description={item.description}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

const Mission = ({ ref }) => {
	const data = [
		{
			icon: agile,
			title: "Agile Methodology",
			description: "It employs iterative development, continuous collaboration",
		},
		{
			icon: modern,
			title: "Modern Technologies",
			description:
				"Our team specializes in creating innovative solutions that leverage ",
		},
		{
			icon: modernTech,
			title: "Modern Technologies",
			description:
				"Our team specializes in creating innovative solutions that leverage ",
		},
	];
	return (
		<div ref={ref} className="pt-10 pb-20">
			<div className="flex items-center justify-center">
				<div className="text-center bg-gradient-to-r from-amber-700 to-amber-400 inline-block text-transparent bg-clip-text uppercase">
					OUR Mission
				</div>
			</div>

			<div className="pt-10">
				<div className="text-center text-xl md:text-2xl font-semibold text-zinc-200">
					We are a team of expert developers. We build tech that scales
				</div>
				<div className="text-center px-5 md:px-0 text-md md:text-xl font-medium text-zinc-200 pt-3">
					There are tons of agencies out there Why work With us?
					<br />
					Check Out few things which sets us apart:
				</div>
			</div>
			<div className="flex flex-wrap justify-evenly mt-20">
				{data.map((item) => (
					<div key={item.title} className="w-80 p-4">
						<img src={item.icon} className="w-10 md:w-12" alt="" />
						<div className="mt-3 text-xl md:text-2xl font-bold text-zinc-200">
							{item.title}
						</div>
						<div className="text-zinc-200 text-sm md:text-md pt-3">
							{item.description}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const VideoConsulting = ({ ref }) => {
	const data = [
		{
			title: "Personalized Advice",
			description:
				"Our seasoned mentors will sit down with you virtually, understanding your startup's goals, challenges, and aspirations. They'll provide tailored guidance to help you make informed decisions.",
		},
		{
			title: "Problem Solving",
			description:
				"Have a specific issue or question that's been keeping you up at night? Bring it to our consulting sessions, and we'll work together to find a solution.",
		},
		{
			title: "Strategy Development",
			description:
				"Whether it's refining your business plan, optimizing your user interface, or planning your app development roadmap, we're here to assist you in crafting a solid strategy.",
		},
	];

	return (
		<div ref={ref} className="py-20">
			<div className="flex items-center justify-center">
				<div className="px-5 md:px-0 text-center bg-gradient-to-r to-cyan-500 from-blue-600 inline-block text-transparent bg-clip-text uppercase">
					Unlock the Power of 3 Free Video Consulting Sessions
				</div>
			</div>
			<div className="px-5 md:px-0 mt-10 text-center text-md md:text-xl font-medium text-zinc-200">
				Navigating the challenges of launching and growing a startup can be a
				daunting task.
				<br />
				That's why at CTO Ninja, we're here to provide you with the guidance and
				support you need.
				<br />
				We offer an exclusive opportunity for startups - three free video
				consulting <br /> sessions to help you address the unique hurdles you're
				facing.
			</div>
			<div className="px-5 md:px-0 mt-10 text-center text-md md:text-xl font-bold text-zinc-200 pt-3">
				Here's what you can expect from these sessions:
			</div>
			<div className="flex flex-col md:flex-row items-center justify-evenly my-10">
				{data.map((item) => (
					<div className="px-10 py-10 bg-[#03162A] h-80 w-80 rounded-md border-2 border-sky-950 mb-5 md:mb-0">
						<div className="text-xl font-bold text-zinc-200">{item.title}</div>
						<div className="text-zinc-200 mt-3">{item.description}</div>
					</div>
				))}
			</div>
			<div className="px-5 md:px-0 text-zinc-200 text-md md:text-xl pt-10 text-center">
				These sessions are your chance to tap into our expertise and get a taste
				of how we can help your startup thrive.
				<br />
				There's no commitment required – simply take advantage of these free
				sessions, and we'll be by your side every step of the way.
			</div>
			<div className="px-5 md:px-0 text-zinc-200 text-md md:text-xl pt-10 text-center">
				Ready to get started? Contact us today to schedule your first free video
				consulting session and embark on
				<br />
				the path to startup success.
			</div>
			<div className="flex items-center justify-center mt-20">
				<Button
					sx={{ textTransform: "initial" }}
					variant="contained"
					className="getAQuote"
					style={{
						display: "inline-block",
						marginRight: 0,
					}}>
					Book Now
				</Button>
			</div>
		</div>
	);
};

export default function Startup() {
	const ref = useRef(null);
	const heroRef = useRef(null);
	const consultingRef = useRef(null);
	const missionRef = useRef(null);
	const videoRef = useRef(null);
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		switch (location.hash) {
			case "#about-us":
				missionRef.current?.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
				break;
			case "#service":
				consultingRef.current?.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
				break;
			case "#case-study":
				videoRef.current?.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
				break;
			default:
				break;
		}
	}, [location.hash]);

	return (
		<div
			ref={ref}
			style={{ backgroundColor: "#0d0c22", fontFamily: "DM Sans" }}>
			<HeroSection ref={heroRef} />
			<div ref={consultingRef}>
				<ConsultingServices />
			</div>
			<div ref={missionRef}>
				<Mission />
			</div>
			<Clients />
			<div ref={videoRef}>
				<VideoConsulting />
			</div>
		</div>
	);
}

/**
 *
 */
